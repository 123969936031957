/* eslint-env browser */
import React from "react";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import { Container, Grid } from "@mui/material";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import Stack from "../components/Stack.js";
import SearchContext from "../contexts/SearchContext.js";
import useFilter from "@tzmedical/react-hooks/useFilter";

//---------------------------------------------------------------------------
// List of stacks that are public
//---------------------------------------------------------------------------
const developerStacks = [
  { suffix: "ajspivey", domain: "bitrhythm.test", name: "AJ" },
  { suffix: "amills", domain: "bitrhythm.test", name: "Alex" },
  { suffix: "arhoads", domain: "bitrhythm.test", name: "Ash" },
  { suffix: "chammond", domain: "bitrhythm.test", name: "Chris" },
  { suffix: "erand", domain: "bitrhythm.test", name: "Ella" },
  { suffix: "jvinkemulder", domain: "bitrhythm.test", name: "John" },
  { suffix: "kjames", domain: "bitrhythm.test", name: "Katie" },
  { suffix: "kwilcox", domain: "bitrhythm.test", name: "Kaed" },
  { suffix: "troderick", domain: "bitrhythm.test", name: "Travis" },
];

const DeveloperStacks = () => {
  //---------------------------------------------------------------------------
  // Search support
  //---------------------------------------------------------------------------
  const { search } = React.useContext(SearchContext);
  const searchFields = {
    name: "name",
  };
  const filteredStacks = useFilter(developerStacks, search, searchFields);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <Container maxWidth="xl">
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        {filteredStacks.map((stack) => (
          <Grid item xs={12} sm={6} md={4} xl={3} key={stack.suffix}>
            <Stack stack={stack} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default DeveloperStacks;
